import React, { useState, useEffect } from 'react';
import ArthaGateway from 'service/ArthaGateway';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Loader } from '../Widgets';
import { PaymentRequest, PaymentRequestSummary, Session } from 'service';
import moment from 'moment';

export const PaymentRequestPage = () => {
    const appdata = useSelector((state: RootState) => state.appdata);
    const [session, setSession] = useState<Session>();
    const [date, setDate] = useState<string>(moment().format("YYYY-MM-DD"));
    const [status, setStatus] = useState<Number>(0);
    const [paymentRequests, setPaymentRequests] = useState<PaymentRequest[]>([]);
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
    const [summary, setSummary] = useState<PaymentRequestSummary>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [screenName, setScreenName] = useState<string>("main-screen");
    const navigate = useNavigate();

    useEffect(() => {
        if (!date) {
            setDate(moment().format("YYYY-MM-DD"));
        }
        if (appdata.profile) {
            setSession(appdata.profile.profile);
        }
    }, [appdata]);

    useEffect(() => {
        reloadData();
    }, [date, status]);

    const reloadData = () => {
        localStorage.removeItem("payment_requests");
        loadData();
    }

    const loadData = () => {
        setIsLoading(true);
        ArthaGateway
            .getPaymentRequests(date, status)
            .then((data) => {
                data.forEach((request) => {
                    request.isExpanded = false;
                    request.isSelected = false;
                });
                setPaymentRequests(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        let summary: PaymentRequestSummary = {
            requestsCount: 0,
            approvedRequestsCount: 0,
            totalRequestedAmount: 0,
            totalApprovedAmount: 0,
            totalPaidAmount: 0
        };

        if (paymentRequests) {
            summary.requestsCount = paymentRequests.length;
            summary.totalRequestedAmount = paymentRequests.reduce((total, request) => (total + request.requestedAmount + request.advanceAmount), 0);
            let ar = paymentRequests.filter(r => r.status === 'Approved');
            summary.approvedRequestsCount = ar.length;
            summary.totalApprovedAmount = ar.reduce((total, request) => (total + request.approvedAmount), 0);
            summary.totalPaidAmount = ar.reduce((total, request) => (total + request.paidAmount), 0);
        }
        setSummary(summary);
    }, [paymentRequests]);

    const toggleExapnd = (target) => {
        paymentRequests.map((request) => {
            if (request.id === target.id) {
                request.isExpanded = !request.isExpanded;
            }
            else {
                request.isExpanded = false;
            }
        });
        setPaymentRequests([...paymentRequests]);
    }

    const currencyFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(value);

    const persistSessionData = (request?: PaymentRequest) => {
        if (request) {
            localStorage.setItem("request", JSON.stringify(request));
        }
        else {
            localStorage.removeItem("request");
        }
        localStorage.setItem("payment_requests", JSON.stringify(paymentRequests));
    }

    const setApprovedAmount = (amount: number) => {
        if (paymentRequest) {
            setPaymentRequest({ ...paymentRequest, approvedAmount: amount });
        }
    }

    const showApproveScreen = (request: PaymentRequest) => {
        setPaymentRequest({ ...request, approvedAmount: (request.requestedAmount + request.advanceAmount) });
        setScreenName("approve-screen");
    }

    const showPostponeScreen = (request: PaymentRequest) => {
        setPaymentRequest({ ...request, approvedAmount: 0, postponedTo: moment(request.date).format("YYYY-MM-DD") });
        setScreenName("postpone-screen");
    }

    const showCancelScreen = (request: PaymentRequest) => {
        setPaymentRequest({ ...request });
        setScreenName("cancel-screen");
    }

    const gotoMainScreen = () => {
        setScreenName("main-screen");
    }

    const approvePayment = () => {
        if (paymentRequest) {
            paymentRequest.status = "Approved";
            paymentRequest.approvedAt = moment().format("YYYY-MM-DD HH:mm:ss");
            paymentRequest.approvedBy = appdata.profile?.profile?.user.id;
            ArthaGateway
                .updatePaymentRequest(paymentRequest)
                .then((data) => {
                    setScreenName("main-screen");
                    loadData();
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const approveAll = () => {
        setIsLoading(true);
        let selectedRequests = paymentRequests.filter(r => r.isSelected === true);
        selectedRequests.forEach((request) => {
            request.status = "Approved";
            request.approvedAt = moment().format("YYYY-MM-DD HH:mm:ss");
            request.approvedBy = appdata.profile?.profile?.user.id;
            request.approvedAmount = request.requestedAmount + request.advanceAmount;
            ArthaGateway
                .updatePaymentRequest(request)
                .then((data) => {
                    console.log("Approved request: " + request.id);
                })
                .catch(error => {
                    console.log(error);
                });
        });
        setScreenName("main-screen");
        loadData();
    }

    const cancelPayment = () => {
        if (paymentRequest) {
            paymentRequest.status = "Cancelled";
            paymentRequest.cancelledAt = moment().format("YYYY-MM-DD HH:mm:ss");
            paymentRequest.cancelledBy = appdata.profile?.profile?.user.id;
            paymentRequest.approvedAmount = 0;
            ArthaGateway
                .updatePaymentRequest(paymentRequest)
                .then((data) => {
                    setScreenName("main-screen");
                    loadData();
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const postponePayment = () => {
        if (paymentRequest) {
            paymentRequest.postponedBy = appdata.profile?.profile?.user.id;
            ArthaGateway
                .postponePaymentRequest(paymentRequest)
                .then((data) => {
                    setScreenName("main-screen");
                    loadData();
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const showApproveAllScreen = () => {
        let selectedRequests = paymentRequests.filter(r => r.isSelected === true);
        setScreenName("approve-all-screen");
    }

    const setApproveDate = (date: string) => {
        if (paymentRequest) {
            setPaymentRequest({ ...paymentRequest, date: date });
        }
    }

    const setPostponedDate = (date: string) => {
        if (paymentRequest) {
            setPaymentRequest({ ...paymentRequest, postponedTo: date });
        }
    }

    const setComment = (comment: string) => {
        if (paymentRequest) {
            setPaymentRequest({ ...paymentRequest, comment: comment });
        }
    }

    return (
        <div className='page-payment-request'>
            {isLoading &&
                <Loader />
            }
            {!isLoading &&
                <>
                    {screenName === "main-screen" &&
                        <>
                            <form>
                                <div className="mb-3">
                                    <input type="date" className="form-control" id="date" value={date} onChange={(e) => setDate(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <div className="btn btn-dark btn-sm" onClick={reloadData} style={{ width: "100%" }}>
                                        <FontAwesomeIcon icon={faRefresh} style={{ marginRight: 10 }} />
                                        Reload data
                                    </div>
                                </div>
                            </form>
                            {session?.activities && session?.activities.indexOf("approve_paymentRequest") > -1 &&
                                <>
                                    {paymentRequests && paymentRequests.length > 0 &&
                                        <>
                                            <div className='row mb-3'>
                                                <div className='col-6 col-md-3 mb-1'>
                                                    <div className="card text-center">
                                                        <div className="card-header">
                                                            Number of requests
                                                        </div>
                                                        <div className="card-body">
                                                            <h5>{summary?.requestsCount}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-6 col-md-3 mb-1'>
                                                    <div className="card text-center">
                                                        <div className="card-header">
                                                            Approved requests
                                                        </div>
                                                        <div className="card-body">
                                                            <h5>{summary?.approvedRequestsCount}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-6 col-md-3 mb-1'>
                                                    <div className="card text-center">
                                                        <div className="card-header">
                                                            Requested Amount
                                                        </div>
                                                        <div className="card-body">
                                                            <h5>{currencyFormat(summary?.totalRequestedAmount)}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-6 col-md-3 mb-1'>
                                                    <div className="card text-center">
                                                        <div className="card-header">
                                                            Approved Amount
                                                        </div>
                                                        <div className="card-body">
                                                            <h5>{currencyFormat(summary?.totalApprovedAmount)}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {moment(date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") &&
                                                <div className='row mb-3'>
                                                    <div className="col-6">
                                                        <input type="checkbox" id="select-all" onChange={(e) => { paymentRequests.forEach((request) => { if (request.status === 'Requested') { request.isSelected = e.target.checked } }); setPaymentRequests([...paymentRequests]); }} />
                                                        <label htmlFor="select-all" style={{ marginLeft: "0.5rem" }}>Select all</label>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="btn-group" role="group" style={{ float: 'right' }} >
                                                            <div className="btn btn-sm btn-primary" onClick={() => showApproveAllScreen()}>
                                                                Approve All
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                    <div className="accordion" id="requestAccordion">
                                        {(!paymentRequests || paymentRequests.length == 0) &&
                                            <div className='alert alert-danger w-100'>No requests raised for the day</div>
                                        }
                                        {paymentRequests && paymentRequests.map((request) => (
                                            <>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id={request.id.toString()} onClick={() => toggleExapnd(request)}>
                                                        <div className={request?.isExpanded === true ? "accordion-button active bg-dark" : "accordion-button collapsed"}>
                                                            <div>
                                                                <div>
                                                                    {moment(date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && request.status === "Requested" &&
                                                                        <input type="checkbox" checked={request.isSelected} onChange={() => { request.isSelected = !request.isSelected; setPaymentRequests([...paymentRequests]); }} style={{ marginRight: "1rem" }} />
                                                                    }
                                                                    {request.party.name}
                                                                </div>
                                                                <div>
                                                                    <div style={{ marginRight: "0.5rem", fontSize: "1.2rem", fontWeight: "bolder", float: "left" }}>{currencyFormat((request.requestedAmount + request.advanceAmount))}</div>
                                                                    {request.status === "Requested" &&
                                                                        <div className="badge bg-primary badge-sm">{request.status}</div>
                                                                    }
                                                                    {request.status === "Approved" &&
                                                                        <div className="badge bg-success badge-sm">{request.status}</div>
                                                                    }
                                                                    {request.status === "Cancelled" &&
                                                                        <div className="badge bg-danger badge-sm">{request.status}</div>
                                                                    }
                                                                    {request.status === "Postponed" &&
                                                                        <div className="badge bg-danger badge-sm">{request.status}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </h2>
                                                    <div id={"d" + request.id.toString()} className={request.isExpanded === true ? "accordion-collapse show" : "accordion-collapse collapse"} aria-labelledby={"d" + request.id.toString()}>
                                                        <div className="accordion-body" style={{ backgroundColor: "#ccc" }}>
                                                            <ul className="list-group list-group-flush">
                                                                <li className="list-group-item">
                                                                    Vendor
                                                                    <div className="fw-bold">{request.party.name}</div>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    Group
                                                                    <div className="fw-bold">{request.party.group}</div>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    Towards
                                                                    <div className="fw-bold">{request.headOfAccount}</div>
                                                                </li>
                                                                {request.party.accountNumber != null && request.party.accountNumber.length > 0 &&
                                                                    <li className="list-group-item">
                                                                        Bank
                                                                        <div className="fw-bold">{request.party.accountNumber}</div>
                                                                        <div className="fw-bold">{request.party.ifscCode}</div>
                                                                        <div className="fw-bold">{request.party.bankName}</div>
                                                                        <div className="fw-bold">{request.party.branch}</div>
                                                                    </li>
                                                                }
                                                                {(request.party.accountNumber == null || request.party.accountNumber.length == 0) &&
                                                                    <li className="list-group-item">
                                                                        <div className="fw-bold">Bank details not available</div>
                                                                    </li>
                                                                }
                                                                <li className="list-group-item">
                                                                    Balance
                                                                    <div className="fw-bold">{currencyFormat((request.party.balance))}</div>
                                                                </li>
                                                                {request.requestedAmount > 0 &&
                                                                    <li className="list-group-item">
                                                                        Requested Amount
                                                                        <div className="fw-bold">{currencyFormat(request.requestedAmount)}</div>
                                                                    </li>
                                                                }
                                                                {request.advanceAmount > 0 &&
                                                                    <li className="list-group-item">
                                                                        Advance Amount
                                                                        <div className="fw-bold">{currencyFormat(request.advanceAmount)}</div>
                                                                    </li>
                                                                }
                                                                <li className="list-group-item">
                                                                    Status<br />
                                                                    {request.status === "Requested" &&
                                                                        <div className="badge bg-primary badge-lg">{request.status}</div>
                                                                    }
                                                                    {request.status === "Approved" &&
                                                                        <div className="badge bg-success badge-lg">{request.status}</div>
                                                                    }
                                                                    {request.status === "Cancelled" &&
                                                                        <div className="badge bg-danger badge-lg">{request.status}</div>
                                                                    }
                                                                    {request.status === "Postponed" &&
                                                                        <div className="badge bg-danger badge-lg">{request.status}</div>
                                                                    }
                                                                </li>
                                                                {request.status === "Approved" && request.approvedAmount > 0 &&
                                                                    <>
                                                                        <li className="list-group-item">
                                                                            Approved Amount
                                                                            <div className="fw-bold">{currencyFormat(request.approvedAmount)}</div>
                                                                        </li>
                                                                        <li className="list-group-item">
                                                                            Approved by
                                                                            <div className="fw-bold">{request.approvedByName}</div>
                                                                        </li>
                                                                    </>
                                                                }
                                                                {request.status === "Postponed" &&
                                                                    <>
                                                                        <li className="list-group-item">
                                                                            Postponed to
                                                                            <div className="fw-bold">{moment(request.postponedTo).format("DD/MM/YYYY")}</div>
                                                                        </li>
                                                                        <li className="list-group-item">
                                                                            Postponed by
                                                                            <div className="fw-bold">{request.postponedByName}</div>
                                                                        </li>
                                                                    </>
                                                                }
                                                                {request.status === "Cancelled" &&
                                                                    <>
                                                                        <li className="list-group-item">
                                                                            Cancelled by
                                                                            <div className="fw-bold">{request.cancelledByName}</div>
                                                                        </li>
                                                                    </>
                                                                }
                                                                <li className="list-group-item">
                                                                    Notes
                                                                    <div className="fw-bold" dangerouslySetInnerHTML={{ __html: request.notes }}></div>
                                                                </li>
                                                                {request.comment && request.comment.length > 0 &&
                                                                    <li className="list-group-item">
                                                                        Comment
                                                                        <div className="fw-bold" dangerouslySetInnerHTML={{ __html: request.comment }}></div>
                                                                    </li>
                                                                }
                                                            </ul>
                                                            {moment(date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") &&
                                                                <div className="btn-group d-flex" role="group">
                                                                    {request.status === "Requested" &&
                                                                        <>
                                                                            <div className="btn btn-success w-100" title="Approve" onClick={() => showApproveScreen(request)}>
                                                                                Approve
                                                                            </div>
                                                                            <div className="btn btn-primary w-100" title="Postpone" onClick={() => showPostponeScreen(request)}>
                                                                                Postpone
                                                                            </div>
                                                                            <div className="btn btn-danger w-100" title="Whatsapp" onClick={() => showCancelScreen(request)}>
                                                                                Cancel
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    {request.status === "Approved" &&
                                                                        <>
                                                                            <div className="btn btn-success w-100" title="Whatsapp" onClick={() => showApproveScreen(request)}>
                                                                                Edit
                                                                            </div>
                                                                            <div className="btn btn-danger w-100" title="Whatsapp" onClick={() => showCancelScreen(request)}>
                                                                                Cancel
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </>
                            }
                            {!session?.activities || session?.activities.indexOf("approve_paymentRequest") === -1 &&
                                <div className='alert alert-danger w-100'>You are not authorized to view this page</div>
                            }
                        </>
                    }
                    {screenName === "approve-screen" &&
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                Vendor
                                <div className="fw-bold">{paymentRequest?.party.name}</div>
                            </li>
                            <li className="list-group-item">
                                Vendor Balance
                                <div className="fw-bold">{currencyFormat((paymentRequest?.party.balance))}</div>
                            </li>
                            {paymentRequest?.requestedAmount != undefined && paymentRequest?.requestedAmount > 0 &&
                                <li className="list-group-item">
                                    Requested Amount
                                    <div className="fw-bold">{currencyFormat(paymentRequest?.requestedAmount)}</div>
                                </li>
                            }
                            {paymentRequest?.advanceAmount != undefined && paymentRequest?.advanceAmount > 0 &&
                                <li className="list-group-item">
                                    Advance Amount
                                    <div className="fw-bold">{currencyFormat(paymentRequest?.advanceAmount)}</div>
                                </li>
                            }
                            <li className="list-group-item">
                                <div className="form-outline mb-4">
                                    <label className="form-label">Approved Amount</label>
                                    <input type="input" className="form-control" value={paymentRequest?.approvedAmount} onChange={(e) => setApprovedAmount(parseInt(e.target.value))} />
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="form-outline mb-4">
                                    <label className="form-label">Comment</label>
                                    <textarea rows={3} className="form-control" value={paymentRequest?.comment} onChange={(e) => setComment(e.target.value)}></textarea>
                                </div>
                            </li>
                            <div className="btn-group d-flex" role="group">
                                <div className="btn btn-danger w-100" title="Whatsapp" onClick={() => gotoMainScreen()}>
                                    Cancel
                                </div>
                                <div className="btn btn-success w-100" title="Whatsapp" onClick={() => approvePayment()}>
                                    Confirm
                                </div>
                            </div>
                        </ul>
                    }
                    {screenName === "cancel-screen" &&
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                Vendor
                                <div className="fw-bold">{paymentRequest?.party.name}</div>
                            </li>
                            <li className="list-group-item">
                                Vendor Balance
                                <div className="fw-bold">{currencyFormat((paymentRequest?.party.balance))}</div>
                            </li>
                            {paymentRequest?.requestedAmount != undefined && paymentRequest?.requestedAmount > 0 &&
                                <li className="list-group-item">
                                    Requested Amount
                                    <div className="fw-bold">{currencyFormat(paymentRequest?.requestedAmount)}</div>
                                </li>
                            }
                            {paymentRequest?.advanceAmount != undefined && paymentRequest?.advanceAmount > 0 &&
                                <li className="list-group-item">
                                    Advance Amount
                                    <div className="fw-bold">{currencyFormat(paymentRequest?.advanceAmount)}</div>
                                </li>
                            }
                            <li className="list-group-item">
                                <div className="form-outline mb-4">
                                    <label className="form-label">Approved Amount</label>
                                    <div className="fw-bold">{currencyFormat(paymentRequest?.approvedAmount)}</div>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="form-outline mb-4">
                                    <label className="form-label">Comment</label>
                                    <textarea rows={3} className="form-control" value={paymentRequest?.comment} onChange={(e) => setComment(e.target.value)}></textarea>
                                </div>
                            </li>
                            <div className="btn-group d-flex" role="group">
                                <div className="btn btn-danger w-100" title="Whatsapp" onClick={() => gotoMainScreen()}>
                                    Cancel
                                </div>
                                <div className="btn btn-success w-100" title="Whatsapp" onClick={() => cancelPayment()}>
                                    Confirm Cancel Payment
                                </div>
                            </div>
                        </ul>
                    }
                    {screenName === "postpone-screen" &&
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                Vendor
                                <div className="fw-bold">{paymentRequest?.party.name}</div>
                            </li>
                            {paymentRequest?.requestedAmount != undefined && paymentRequest?.requestedAmount > 0 &&
                                <li className="list-group-item">
                                    Requested Amount
                                    <div className="fw-bold">{currencyFormat(paymentRequest?.requestedAmount)}</div>
                                </li>
                            }
                            {paymentRequest?.advanceAmount != undefined && paymentRequest?.advanceAmount > 0 &&
                                <li className="list-group-item">
                                    Advance Amount
                                    <div className="fw-bold">{currencyFormat(paymentRequest?.advanceAmount)}</div>
                                </li>
                            }
                            <li className="list-group-item">
                                <div className="form-outline mb-4">
                                    <label className="form-label">Postpone to</label>
                                    <input type="date" className="form-control" value={paymentRequest?.postponedTo} onChange={(e) => setPostponedDate(e.target.value)} />
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="form-outline mb-4">
                                    <label className="form-label">Comment</label>
                                    <textarea rows={3} className="form-control" value={paymentRequest?.comment} onChange={(e) => setComment(e.target.value)}></textarea>
                                </div>
                            </li>
                            <div className="btn-group d-flex" role="group">
                                <div className="btn btn-danger w-100" title="Whatsapp" onClick={() => gotoMainScreen()}>
                                    Cancel
                                </div>
                                <div className="btn btn-success w-100" title="Whatsapp" onClick={() => postponePayment()}>
                                    Confirm
                                </div>
                            </div>
                        </ul>
                    }
                    {screenName === "approve-all-screen" &&
                        <>
                            <div className='row'>
                                <div className='col-12 mb-4'>
                                    <ul className="list-group list-group-flush">
                                        {paymentRequests && paymentRequests.map((request) => (
                                            <>
                                                {request.isSelected === true &&
                                                    <li className="list-group-item">
                                                        {request.party.name}
                                                        <div className="fw-bold" style={{ float: 'right' }}>{currencyFormat(request.requestedAmount + request.advanceAmount)}</div>
                                                    </li>
                                                }
                                            </>
                                        ))}
                                        {paymentRequests && paymentRequests.filter(r => r.isSelected).length == 0 &&
                                            <div className='alert alert-danger w-100'>Please select atleast 1 request to approve</div>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="btn-group d-flex" role="group">
                                <div className="btn btn-danger w-100" title="Whatsapp" onClick={() => gotoMainScreen()}>
                                    Cancel
                                </div>
                                {paymentRequests && paymentRequests.filter(r => r.isSelected).length > 0 &&
                                    <div className="btn btn-success w-100" title="Whatsapp" onClick={() => approveAll()}>
                                        Approve
                                    </div>
                                }
                            </div>
                        </>
                    }
                </>
            }
        </div>
    );
};